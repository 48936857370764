body {
   background-image: url("./images/tlo1.jpg") !important;
   background-color: #cccccc;
}

.cal {
  width: 100% !important;
}

.ui.dropdown:not(.button)>.default.text {
  color: #000 !important;
}

.CalendarDay:active {
  background-color: #000 !important;
  color: #fff !important;
}
